import React, {useRef, useState} from "react";
import {
    Contact, convertToFloorplanCardData,
    DriveGallery,
    FeatureSection,
    FloorplanCardData,
    FloorplanSectionSkeleton,
    FloorplansSection, HomePageSpecialSection,
    PropertyDetails,
    PropertyLocation,
    ReviewSection,
    sortFloorplans,
    TeamSection, trackContactClicked
} from "@renaissancerentals/renaissance-component";
import {HeroSection} from "../components/hero/HeroSection";
import {ActionSection} from "../components/action/ActionSection";
import {AmenitiesSection} from "../components/amenities/AmenitiesSection";
import {TourSection} from "../components/tour/TourSection";
import {MainLayout} from "../layout/MainLayout";
import {propertyId} from "../services/DataService";

export const Home: React.FC<PageProps> = ({property, isLoading, contact}) => {


    const [showContactModal, setShowContactModal] = useState(false);
    const [showApplicationModal, setShowApplicationModal] = useState(false);
    const floorPlanRef = useRef<HTMLDivElement>(null);
    const amenitiesRef = useRef<HTMLDivElement>(null);
    const mapRef = useRef<HTMLDivElement>(null);

    const handleRefToMap = () => {
        mapRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };

    const handleRefToFloorPlan = () => {
        floorPlanRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };

    const handleRefToAmenities = () => {
        amenitiesRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };

    return (
        <MainLayout handleRefToFloorPlan={handleRefToFloorPlan}
                    handleRefToMap={handleRefToMap} isMainPage={true}
                    showApplicationModal={showApplicationModal} showContactModal={showContactModal}
                    setShowApplicationModal={setShowApplicationModal}
                    setShowContactModal={setShowContactModal}
                    contact={contact} property={property}
        >

            <HomePageSpecialSection propertyId={property.id}/>

            <HeroSection handleRefToFloorPlan={handleRefToFloorPlan}/>
            <FeatureSection>
                <div className="container">
                    <p>Sit back & imagine yourself on a warm Bloomington evening relaxing on your
                        beautifully
                        landscaped rooftop patio. Inside you’re welcomed home with a warm, inviting and open design.
                        Your apartment suite is exquisitely finished; from beautiful woodwork, to stainless steel
                        appliances, to modern Tuscan-style finishes.</p>
                    <p>Located at the corner of&nbsp;&nbsp;<a href="#location" onClick={handleRefToMap}>15th &
                        Walnut</a>&nbsp;&nbsp;|&nbsp;&nbsp; <a
                        href="#amenities" onClick={handleRefToAmenities}>Learn More »</a>
                    </p>
                </div>
            </FeatureSection>
            <ActionSection handleApplyClicked={() => setShowApplicationModal(true)}
                           handleContactClicked={() => {
                               setShowContactModal(true);
                               trackContactClicked("scholars-rooftop");
                           }}
                           handleRefToFloorPlan={handleRefToFloorPlan}
                           isLoading={isLoading}
                           floorplans={property.floorplans}/>
            <div ref={amenitiesRef} id="amenities" className="reference">
            </div>
            <AmenitiesSection handleRefToMap={handleRefToMap}/>

            <TourSection/>
            <div className="container">
                <h2 className="heading"><span className="emphasized">Images</span></h2>
                {isLoading ? <></> : <DriveGallery
                    propertyId={propertyId}
                    driveId={property.photosFolderId}
                    initialSize={9}
                    type="simple"
                    showName={false}
                />}

            </div>
            <div ref={floorPlanRef} id="floorplan" className="reference">
            </div>

            {isLoading ?
                <FloorplanSectionSkeleton/> :
                <>{property?.floorplans ? <FloorplansSection
                    propertyId={propertyId}
                    floorplans={
                        sortFloorplans(
                            property.floorplans.filter(floorplan => floorplan.active).map(floorplan => convertToFloorplanCardData(floorplan)
                            ), "featured")
                    }/> : ""}

                </>

            }
            {isLoading ? <></> : <ReviewSection property={property} starColor="orange"/>}

            <div ref={mapRef} id="location" className="reference">
            </div>
            <div className="container">
                <h2 className="heading"><span className="emphasized">Location</span></h2>
            </div>
            <PropertyLocation property={property} isLoading={isLoading} handleRefToContact={() => {
                setShowContactModal(true);
                trackContactClicked("scholars-rooftop");
            }}/>


            <TeamSection isLoading={isLoading} teamMembers={property.teamMembers} propertyId={propertyId}/>


        </MainLayout>
    );
};

export interface PageProps {
    isLoading: boolean;
    property: PropertyDetails;
    contact: Contact;
}
